import React from "react";
import {
  Grid,
  Box,
  CardContent,
  Chip,
  Stack,
  Card,
  CardHeader,
  Button,
  LinearProgress,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import AlertPopup from "../../../Components/Items/AlertPopup";
import withAuth from "../../../Hoc/withAuth";
import { useHistory } from "react-router-dom";
import UploadBannerImage from "../../../Components/ImageUploaders/UploadBannerImage";
import UploadAvatarImage from "../../../Components/ImageUploaders/UploadAvatarImage";

import CreateNewOrgForm from "../../../Components/Forms/Orgs/CreateNewOrgForm";

const MyOrg = ({ token }) => {
  const router = useHistory();

  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // await axios.get(
  //   `${process.env.REACT_APP_PUBLIC_URL}/orgs/myorg`,
  //   config
  // );

  // dispatch({ type: "GET_MY_ORG", payload: data });

  const orgData = useQuery(
    {
      queryKey: "myOrg",
      queryFn: () => {
        return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/orgs/myorg`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      },
    },
    {
      onSuccess: (data) => {
        console.log("data", data);
        setTimeout(() => {
          router.push(`/SchoolAdminDashBoard`);
        }, 1000);
      },
    }
  );

  const data = orgData?.data?.data;

  const UpdateOrg = useMutation(
    ({ id, values }) => {
      return axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/orgs/${id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      enabled: !!data?.uuid,
      onSuccess: (data) => {
        console.log("data", data);
      },
    }
  );

  if (orgData.isLoading) return <LinearProgress />;

  return (
    <>
      <Card sx={{ mt: 3 }}>
        <CardHeader
          title="Create Organisations Profile"
          subheader="Please fill in the form to create your Organisations profile"
          action={
            <Button
              onClick={() => {
                router.push(`/org/${data?.uuid}`);
              }}>
              View Live Page
            </Button>
          }
        />

        <CardContent>
          <Grid container>
            <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
              <Stack sx={{ mb: 2 }}>
                {data?.uuid && (
                  <UploadAvatarImage
                    uuid={data.uuid}
                    token={token}
                    type="avatar"
                  />
                )}
              </Stack>
              {data?.uuid && (
                <UploadBannerImage uuid={data.uuid} token={token} />
              )}

              <CardContent>
                {data.uuid && (
                  <CreateNewOrgForm UpdateOrg={UpdateOrg} data={data} />
                )}
              </CardContent>

              {data?.uuid && (
                <>
                  <Box display="flex" justifyContent="space-between">
                    <div></div>
                    <div>
                      <Chip
                        sx={{ m: 1 }}
                        variant="contained"
                        label={`Created at : ${data?.createdAt}`}
                      />
                    </div>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <AlertPopup
        open={UpdateOrg?.isError}
        message={UpdateOrg?.error?.response?.data?.message}
        severity="error"
      />

      <AlertPopup
        open={UpdateOrg?.isSuccess}
        message="Profile Updated Successfully"
        severity="success"
      />
    </>
  );
};

export default withAuth(MyOrg);
