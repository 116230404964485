import {
  Alert,
  Avatar,
  Box,
  Button,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { AdminGetOrg } from "../../../Redux/actions/Admin";
import ModeEditTwoToneIcon from "@mui/icons-material/ModeEditTwoTone";
import { sendNotification } from "../../../Redux/actions/Notifications";

import UploadAvatarImage from "../../../Components/ImageUploaders/UploadAvatarImage";
import UploadBannerImage from "../../../Components/ImageUploaders/UploadBannerImage";
import withAuth from "../../../Hoc/withAuth";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import AlertPopup from "../../../Components/Items/AlertPopup";
import { url } from "../../../Redux/actions/URL";
import CreateNewOrgForm from "../../../Components/Forms/Orgs/CreateNewOrgForm";

const AdminEditOrg = ({ token }) => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const AdminData = useSelector((state) => state.AdminData);
  const { error, loading, org, orgAction } = AdminData;

  useEffect(() => {
    dispatch(AdminGetOrg({ id }));
  }, [id, dispatch, orgAction]);

  const getOrgAdmin = useQuery({
    queryKey: ["getOrgAdmin", id],
    queryFn: () =>
      axios.get(`${process.env.REACT_APP_PUBLIC_URL}/admin/orgs/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
  });

  const data = getOrgAdmin?.data?.data;
  // Actions

  const AdminApproveOrg = useMutation({
    mutationFn: async () => {
      await axios.get(`${url}/admin/orgs/approve/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: () => {
      getOrgAdmin.refetch();
    },
  });

  const adminSuspendOrg = useMutation({
    mutationFn: async () => {
      await axios.get(`${url}/admin/orgs/suspend/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: () => {
      getOrgAdmin.refetch();
    },
  });

  const UpdateOrg = useMutation(
    ({ id, values }) => {
      return axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/orgs/${id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      enabled: !!id,
      onSuccess: (data) => {
        getOrgAdmin.refetch();
      },
    }
  );

  console.log("getOrgAdmin", getOrgAdmin);

  const router = useHistory();

  const handleApproveOrg = () => {
    AdminApproveOrg.mutate();
    let notification = {
      receiverId: org?.userId,
      content: `Your organization ${org?.name} has been approved`,
      title: "Organization Approved",
      type: "Organization",
      severity: "success",
      url: `/myorg/${org?.uuid}`,
    };

    dispatch(sendNotification(notification));
  };
  const handlesSusOrg = () => {
    adminSuspendOrg.mutate();

    let notification = {
      receiverId: data?.userId,
      content: `Your organization ${data?.name} has been suspended`,
      title: "Organization suspended",
      type: "Organization",
      severity: "error",
      url: `/myorg/${data?.uuid}`,
    };

    dispatch(sendNotification(notification));
  };

  const DeleteOrg = useMutation({
    mutationFn: async () => {
      await axios.delete(
        `${process.env.REACT_APP_PUBLIC_URL}/admin/orgs/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: () => {
      router.push("/admin/orgs");
    },
  });

  const handleDeleteOrg = () => {
    DeleteOrg.mutate();
  };

  if (error) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <>
      <Stack
        sx={{ height: 100 }}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Typography variant="h6" align="center">
          Edit Organization
        </Typography>
      </Stack>
      {loading && (
        <Box my={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      <Stack sx={{ mb: 4 }} direction="row" justifyContent="space-between">
        {org?.approved ? (
          <Button color="warning" variant="outlined" onClick={handlesSusOrg}>
            Suspend
          </Button>
        ) : (
          <Button
            onClick={handleApproveOrg}
            color="success"
            variant="contained">
            Approve
          </Button>
        )}

        <Button color="error" onClick={handleDeleteOrg}>
          Delete Org
        </Button>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xl={8} md={8} sm={12} xs={12}>
          <CardContent>
            <Grid container>
              <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                <Stack sx={{ mb: 2 }}>
                  {data?.uuid && (
                    <UploadAvatarImage
                      uuid={data?.uuid}
                      token={token}
                      type="avatar"
                    />
                  )}
                </Stack>
                {data?.uuid && (
                  <UploadBannerImage uuid={data?.uuid} token={token} />
                )}

                <CardContent>
                  {data?.uuid && (
                    <CreateNewOrgForm UpdateOrg={UpdateOrg} data={data} />
                  )}
                </CardContent>

                {data?.uuid && (
                  <>
                    <Box display="flex" justifyContent="space-between">
                      <div>
                        <Chip
                          sx={{ m: 1 }}
                          variant="contained"
                          label={`Created at : ${data?.createdAt}`}
                        />
                      </div>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
        <Grid item xl={4} md={4} sm={12} xs={12}>
          <Typography variant="h6" align="center" sx={{ my: 4 }}>
            Events
          </Typography>

          <List>
            {org?.Events &&
              org?.Events?.map((event) => {
                return (
                  <div key={event.uuid}>
                    <ListItemButton
                      onClick={() => router.push(`/editEvent/${event.uuid}`)}>
                      <ListItem
                        alignItems="flex-start"
                        secondary={
                          <IconButton edge="end" aria-label="delete">
                            <ModeEditTwoToneIcon />
                          </IconButton>
                        }>
                        <ListItemAvatar>
                          <Avatar
                            alt="Remy Sharp"
                            src={`${process.env.REACT_APP_IMAGE_URL}/${event?.banner}`}
                          />
                        </ListItemAvatar>
                        <ListItemText primary={event?.name} />
                      </ListItem>
                    </ListItemButton>
                    <Divider />
                  </div>
                );
              })}
          </List>
        </Grid>
      </Grid>
      <AlertPopup
        open={DeleteOrg.isSuccess}
        severity="success"
        message="Organization Deleted"
      />
      <AlertPopup
        open={UpdateOrg?.isError}
        message={UpdateOrg?.error?.response?.data?.message}
        severity="error"
      />

      <AlertPopup
        open={UpdateOrg?.isSuccess}
        message="Profile Updated Successfully"
        severity="success"
      />

      <AlertPopup
        open={AdminApproveOrg.isError}
        message={AdminApproveOrg.error?.response?.data?.message}
        severity="error"
      />

      <AlertPopup
        open={AdminApproveOrg.isSuccess}
        message="Organization Approved"
        severity="success"
      />

      <AlertPopup
        open={adminSuspendOrg.isError}
        message={adminSuspendOrg.error?.response?.data?.message}
        severity="error"
      />

      <AlertPopup
        open={adminSuspendOrg.isSuccess}
        message="Organization suspended"
        severity="success"
      />
    </>
  );
};

export default withAuth(AdminEditOrg);
