import {
  Alert,
  Button,
  Chip,
  Container,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { url } from "../../Redux/actions/URL";
import withAuth from "../../Hoc/withAuth";
import { DataGridPremium } from "@mui/x-data-grid-premium";

const AdminOrgs = ({ token }) => {
  const router = useHistory();

  const getAllOrg = useQuery({
    queryKey: ["getAllOrg"],
    queryFn: () =>
      axios.get(`${url}/admin/orgs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
  });

  if (getAllOrg.isLoading) {
    return <LinearProgress />;
  }

  if (getAllOrg.isError) {
    return <Alert severity="error">{getAllOrg.error.message}</Alert>;
  }

  console.log("getAllOrg", getAllOrg);

  return (
    <>
      <Stack
        sx={{ height: 100 }}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Typography variant="h6" align="center">
          Organisations
        </Typography>
      </Stack>

      <Container maxWidth="xl" disableGutters>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xl={12} md={12} sm={12} xs={12}>
            <Stack
              sx={{
                height: "80vh",
              }}>
              <DataGridPremium
                rows={getAllOrg.data?.data}
                getRowId={(row) => row.uuid}
                columns={[
                  {
                    field: "name",
                    headerName: "Name",
                    flex: 1,
                  },
                  {
                    field: "approved",
                    headerName: "Status",
                    flex: 1,
                    renderCell: (params) => {
                      return (
                        <Chip
                          label={params.value === true ? "Approved" : "Pending"}
                          color={params.value === true ? "success" : "warning"}
                        />
                      );
                    },
                  },
                  {
                    field: "action",
                    headerName: "Action",
                    flex: 1,
                    renderCell: (params) => {
                      return (
                        <Stack direction="row" spacing={2}>
                          <Button
                            color="primary"
                            onClick={() =>
                              router.push(`/orgs/${params.row.uuid}`)
                            }>
                            View
                          </Button>
                          <Button
                            color="warning"
                            variant="contained"
                            onClick={() =>
                              router.push(`/admin/orgs/${params.row.uuid}`)
                            }>
                            Edit
                          </Button>
                        </Stack>
                      );
                    },
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withAuth(AdminOrgs);
