import axios from "axios";

import React from "react";

import {
  Typography,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";

import FolderIcon from "@mui/icons-material/Folder";
import withAuth from "../../Hoc/withAuth";

const DownloadFileButton = ({ documents, token }) => {
  const handleDownload = async ({ url, name }) => {
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
      });
      const blob = new Blob([response.data]);
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {documents &&
        documents.length > 0 &&
        documents?.map((document) => {
          return (
            <React.Fragment key={document.file_name}>
              <ListItemButton
                onClick={() => {
                  handleDownload({
                    url: `${process.env.REACT_APP_PUBLIC_URL}/manualTracking/downloadFile/${document?.file_name}`,
                    name: document?.original_file,
                  });
                }}
                sx={{ m: 0, pt: 1 }}
                alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.secondary">
                        Document Name
                      </Typography>
                      {"   -   "}
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.secondary">
                        {document?.original_file}
                      </Typography>
                    </>
                  }
                  secondary={
                    <>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body1"
                        color="text.primary">
                        {document?.original_file}
                      </Typography>
                    </>
                  }
                />
              </ListItemButton>
            </React.Fragment>
          );
        })}
    </>
  );
};

export default withAuth(DownloadFileButton);
