import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

export default function BasicPie({ goals, hours }) {
  return (
    <PieChart
      series={[
        {
          data: [
            {
              id: 0,
              value: goals,
              label: "Target Hours",
              color: "#0983fcfe",
            },
            { id: 1, value: hours, label: "Completed", color: "#09ff76ff" },
          ],
          innerRadius: 40,
          highlightScope: { faded: "global", highlighted: "item" },
          faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
        },
      ]}
      width={400}
      height={200}
    />
  );
}
