import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { Field, Form, Formik } from "formik";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Container, styled } from "@mui/system";
import {
  Alert,
  AppBar,
  Avatar,
  Card,
  CardHeader,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  Typography,
  Stack,
  Divider,
  Chip,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import FolderIcon from "@mui/icons-material/Folder";
import CloseIcon from "@mui/icons-material/Close";
import TextfieldWrapper from "../../../Components/Forms/FormComponents/TextfieldWrapper";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import axios from "axios";
import withAuth from "../../../Hoc/withAuth";
import DownloadFileButton from "../../../Components/Forms/DownloadFileButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManualUploadView = ({
  token,
  user,
  ordId,
  getManualUploads,
  captured,
}) => {
  const [open, setOpen] = React.useState(false);

  const HandleSend = useMutation({
    mutationKey: ["manualUpload"],
    mutationFn: ({ uuid, values }) => {
      return axios.put(
        // `${process.env.REACT_APP_PUBLIC_URL}/events/manualEvent`,
        `${process.env.REACT_APP_PUBLIC_URL}/manualTracking/manualUpload/${uuid}`,
        values,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values) => {
    let formData = new FormData();

    values.attachments.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("uuid", values.uuid);

    formData.append("charityName", values.charityName);
    formData.append("charityNumber", values.charityNumber);
    formData.append("charityType", values.charityType);
    formData.append("charityCategory", values.charityCategory);
    formData.append("userId", user?.uuid);
    formData.append("orgId", values.orgId);
    formData.append("hours", values.hours);
    formData.append("date", values.date);
    formData.append("documents", JSON.stringify(values.documents));
    formData.append("status", values.status);
    formData.append("notes", JSON.stringify(values.notes));
    formData.append("sendEmail", values.sendEmail);

    console.log(formData);

    HandleSend.mutate(
      { uuid: values.uuid, values: formData },
      {
        onSuccess: (data) => {
          getManualUploads.refetch();

          // clear form on success

          values.attachments = [];
          values.charityName = "";
          values.charityNumber = "";
          values.charityType = "default";
          values.charityCategory = "None";
          values.hours = "";
          values.date = dayjs();

          handleClose();
        },
      }
    );
  };

  const [note, setNote] = React.useState("");

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        View
      </Button>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              View Student Upload
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <Container>
          <DialogContent>
            <Formik
              initialValues={{
                uuid: captured?.uuid || "",
                charityName: captured?.charityName || "",
                charityNumber: captured?.charityNumber || "",
                charityType: "default",
                charityCategory: "None",
                hours: captured?.hours || "",
                orgId: captured?.orgId || "",
                date: captured?.date ? dayjs(captured?.date) : dayjs(),
                attachments: [],
                documents: captured?.documents || [],
                status: captured?.status || "",
                notes: captured?.notes || [],
                sendEmail: false,
              }}
              enableReinitialize={true}
              onSubmit={(values) => {
                handleSubmit(values);
              }}>
              {({ setFieldValue, values }) => {
                console.log("values", values);
                return (
                  <Form>
                    <Grid container spacing={2} sx={{ mt: 2, py: 2 }}>
                      <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                          name="charityName"
                          label="Charity Name"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                          name="charityNumber"
                          label="Charity Contact Number"
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                          name="hours"
                          label="Hours"
                          type="number"
                        />
                        {values.hours > 8 && (
                          <Alert severity="warning" sx={{ mt: 1 }}>
                            Are you sure?
                          </Alert>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="en-gb">
                          <DatePicker
                            format="LL"
                            variant="inline"
                            value={values.date}
                            label="Event Date"
                            onChange={(date) => setFieldValue("date", date)}
                            inputVariant="outlined"
                            fullWidth
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={12}>
                        <Card sx={{ p: 1 }} variant="outlined">
                          <CardHeader
                            sx={{ mb: 0, pb: 0.5 }}
                            title={<Typography>Attachments</Typography>}
                            subheader={
                              <Typography variant="caption" color="GrayText">
                                Please attach any supporting documents here that
                                will be approved by your teacher
                              </Typography>
                            }
                          />
                          {values?.documents &&
                            values?.documents?.length > 0 && (
                              <DownloadFileButton
                                documents={values?.documents}
                              />
                            )}

                          <Box sx={{ mb: 1 }}></Box>
                          <Field name="attachments" component={FileInput} />
                        </Card>
                      </Grid>

                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                          <Typography>
                            <strong>Status : </strong>
                          </Typography>
                          {values.status === "Approved" ? (
                            <Chip
                              variant="contained"
                              label={"Approved"}
                              color="success"
                            />
                          ) : values.status === "Rejected" ? (
                            <Chip
                              variant="contained"
                              label={"Rejected"}
                              color="error"
                            />
                          ) : (
                            <Chip
                              variant="contained"
                              label={"Pending"}
                              color="warning"
                            />
                          )}
                        </Stack>

                        <Divider sx={{ my: 2 }} />
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}>
                          <Button
                            fullWidth
                            onClick={() => {
                              setFieldValue("status", "Approved");
                            }}
                            variant={
                              values.status === "Approved"
                                ? "contained"
                                : "outlined"
                            }
                            color="success">
                            Approve
                          </Button>
                          <Button
                            fullWidth
                            onClick={() => {
                              setFieldValue("status", "Rejected");
                            }}
                            variant={
                              values.status === "Rejected"
                                ? "contained"
                                : "outlined"
                            }
                            color="error">
                            Reject
                          </Button>
                        </Stack>
                        <Divider sx={{ my: 2 }} />
                      </Grid>

                      <Grid item xs={12}>
                        <Card variant="contained" sx={{ p: 1 }}>
                          <CardHeader
                            sx={{ mb: 0, pb: 0.5 }}
                            title={<Typography>Notes</Typography>}
                            subheader={
                              <Typography variant="caption" color="GrayText">
                                Add any notes here
                              </Typography>
                            }
                          />

                          {values.notes &&
                            values.notes.length > 0 &&
                            values.notes.map((note, index) => {
                              return (
                                <ListItem
                                  key={index}
                                  sx={{ m: 0, pt: 1 }}
                                  alignItems="flex-start"
                                  secondaryAction={
                                    <IconButton
                                      edge="end"
                                      onClick={() => {
                                        let newNotes = values.notes.filter(
                                          (note, i) => i !== index
                                        );
                                        setFieldValue("notes", newNotes);
                                      }}>
                                      <CloseIcon />
                                    </IconButton>
                                  }>
                                  <ListItemText
                                    primary={
                                      <>
                                        <Typography
                                          sx={{ display: "inline" }}
                                          component="span"
                                          variant="body1"
                                          color="text.primary">
                                          {note.note}
                                        </Typography>
                                      </>
                                    }
                                    secondary={
                                      <>
                                        <Typography
                                          sx={{ display: "inline" }}
                                          component="span"
                                          variant="body2"
                                          color="text.secondary">
                                          {note.user}
                                        </Typography>
                                        {"   -   "}
                                        <Typography
                                          sx={{ display: "inline" }}
                                          component="span"
                                          variant="body2"
                                          color="text.secondary">
                                          {new Date(
                                            note.date
                                          ).toLocaleDateString("en-GB")}
                                        </Typography>
                                      </>
                                    }
                                  />
                                </ListItem>
                              );
                            })}

                          <TextField
                            name="notes"
                            label="Notes"
                            fullWidth
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            multiline
                            rows={4}
                          />

                          {note && note.length > 0 && (
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() => {
                                setFieldValue("notes", [
                                  ...values.notes,
                                  {
                                    note: note,
                                    user: user?.email,
                                    date: new Date(),
                                  },
                                ]);
                                setNote("");
                              }}>
                              Save Note
                            </Button>
                          )}
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.sendEmail}
                              onChange={(e) =>
                                setFieldValue("sendEmail", e.target.checked)
                              }
                              name="sendEmail"
                            />
                          }
                          label="Send Response Email"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                          <Button fullWidth onClick={handleClose}>
                            Cancel
                          </Button>
                          <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="secondary">
                            Save
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </Container>
      </Dialog>
    </React.Fragment>
  );
};

export default withAuth(ManualUploadView);

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FileInput = ({ field, form }) => {
  const handleChange = (event) => {
    // set all the files

    const files = event.target.files[0];

    let Afiles = [...field.value, files];

    // setFile(files);
    form.setFieldValue("attachments", Afiles);
  };

  const removeFile = (index) => {
    let Afiles = field.value.filter((file, i) => i !== index);

    form.setFieldValue("attachments", Afiles);
  };

  return (
    <>
      {field.value &&
        field.value.length > 0 &&
        field.value.map((file, index) => {
          return (
            <ListItem
              key={index}
              sx={{ m: 0, pt: 1 }}
              alignItems="flex-start"
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => {
                    removeFile(index);
                  }}>
                  <CloseIcon />
                </IconButton>
              }>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.secondary">
                      Document Name
                    </Typography>
                  </>
                }
                secondary={
                  <>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body1"
                      color="text.primary">
                      {file.name}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          );
        })}

      <Button
        fullWidth
        component="label"
        variant="outlined"
        startIcon={<CloudUploadIcon />}>
        Upload file
        <VisuallyHiddenInput type="file" onChange={handleChange} />
      </Button>
    </>
  );
};
