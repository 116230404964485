import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { updateSchoolData } from "../../Redux/actions/SchoolsActions";
import TextfieldWrapper from "../Forms/FormComponents/TextfieldWrapper";
import SelectWrapper from "../Forms/FormComponents/Select";

import MySlateEditor from "../Forms/MySlateEditor";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditModal = ({ resource }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen(!open);
        }}>
        <Typography color="warning">Edit</Typography>
      </MenuItem>

      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}>
        <DialogTitle>Edit Content</DialogTitle>
        <Formik
          initialValues={{
            title: resource?.title,
            data: resource?.data,
            tag: resource?.tag,
            org_id: resource?.org_id,
          }}
          validationSchema={Yup.object({
            title: Yup.string().required("Title is required*"),
            tag: Yup.string().required("Please select a tag*"),
          })}
          onSubmit={(values) => {
            dispatch(updateSchoolData({ ...values, id: resource.uuid }));
            setOpen(!open);
          }}>
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextfieldWrapper label="Title" name="title" />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectWrapper
                        name="tag"
                        label="Tag"
                        options={{
                          notice: "Notices",
                          news: "News",
                          resource: "Resource",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MySlateEditor
                        value={values.data}
                        onChange={(value) => {
                          setFieldValue("data", value);
                        }}
                      />
                    </Grid>
                    <Divider />
                  </Grid>
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setOpen(false)}>Cancel</Button>
                  <Button type="submit" variant="contained" color="success">
                    Submit
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default EditModal;
