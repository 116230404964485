import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getSchoolGradesClassesRequest,
  getSchoolsListRequest,
} from "../../Redux/actions/AppData";
import withAuth from "../../Hoc/withAuth";
import { getSchoolGrades } from "../../Redux/actions/SchoolStudentsActions";

const SchoolSelect = ({ addUserToSchool }) => {
  const mySchool = useSelector((state) => state.mySchool);

  const { schoolList, schoolGradesClasses } = mySchool;

  const [school, setSchool] = useState("");
  const [grade, setGrade] = useState("");
  const [Class, setClass] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSchoolsListRequest());
  }, [dispatch]);

  const HandelSchoolSelect = () => {
    let values = { org_id: school, grade: grade.uuid, class: Class.uuid };
    addUserToSchool.mutate({ body: values });
  };

  const getSchoolStuff = useCallback(() => {
    dispatch(getSchoolGradesClassesRequest({ id: school }));
    dispatch(getSchoolGrades(school));
  }, [school, dispatch]);

  useEffect(() => {
    getSchoolStuff();
  }, [getSchoolStuff]);

  const schoolStudents = useSelector((state) => state.schoolStudents);

  const { schoolGradesList } = schoolStudents;

  return (
    <div>
      <Stack sx={{ mt: 1 }} spacing={2}>
        <FormControl>
          <InputLabel id="School">Select School</InputLabel>
          <Select
            labelId="School"
            id="School"
            value={school}
            label="Select School"
            onChange={(event) => setSchool(event.target.value)}>
            {schoolList &&
              schoolList.map((school) => {
                return (
                  <MenuItem key={school.uuid} value={school.uuid}>
                    {school.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        {schoolGradesList && school && (
          <FormControl>
            <InputLabel id="GRADE">Grade</InputLabel>
            <Select
              labelId="GRADE"
              id="grade"
              value={grade}
              label="Grade"
              onChange={(event) => setGrade(event.target.value)}>
              {schoolGradesClasses &&
                schoolGradesClasses?.map((grade) => {
                  return (
                    <MenuItem key={grade.uuid} value={grade}>
                      {grade.grade}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
        {grade && (
          <FormControl>
            <InputLabel id="Class">Select Class</InputLabel>
            <Select
              labelId="Class"
              id="Class"
              value={Class}
              label="Select Class"
              onChange={(event) => setClass(event.target.value)}>
              {grade &&
                grade?.SchoolClasses?.map((C) => {
                  return (
                    <MenuItem key={C.uuid} value={C}>
                      {C.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
      </Stack>

      {school && grade && Class && (
        <Button
          sx={{ mt: 3 }}
          fullWidth
          color="secondary"
          onClick={() => HandelSchoolSelect()}
          variant="contained">
          Save
        </Button>
      )}
    </div>
  );
};

export default withAuth(SchoolSelect);
