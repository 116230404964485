import React from "react";

import UploadedCaptureDelete from "./UploadedCaptureDelete";
import { useMutation } from "react-query";
import axios from "axios";
import AlertPopup from "../../../Components/Items/AlertPopup";
import UploadedCaptureView from "./UploadedCaptureView";
import withAuth from "../../../Hoc/withAuth";
import { DataGrid } from "@mui/x-data-grid";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";

const UploadedCaptures = ({ getManualUploads, token }) => {
  let manualHours = getManualUploads.data?.data;

  const deleteManualUpload = useMutation({
    mutationFn: (uploadItem) => {
      return axios.delete(
        `${process.env.REACT_APP_PUBLIC_URL}/manualTracking/userUploads/${uploadItem?.uuid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
  });

  const handleDeleteUpload = (uploadItem) => {
    deleteManualUpload.mutate(uploadItem, {
      onSuccess: (data) => {
        getManualUploads.refetch();
      },
    });
  };

  const columns = [
    {
      field: "view",
      headerName: "View",
      width: 130,
      renderCell: (params) => {
        if (params.row.status === "Approved") {
          return (
            <Button variant="contained" color="primary" disabled>
              View
            </Button>
          );
        }
        return (
          <UploadedCaptureView
            manualUpload={params.row}
            captured={params.row}
            getManualUploads={getManualUploads}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,

      renderCell: (params) => {
        if (params.row.status === null) {
          return (
            <div>
              <span style={{ color: "blue" }}>Pending</span>
            </div>
          );
        }
        return (
          <div>
            {params.row.status === "Approved" ? (
              <span style={{ color: "green" }}>{params.row.status}</span>
            ) : (
              <span style={{ color: "red" }}>{params.row.status}</span>
            )}
          </div>
        );
      },
    },
    { field: "charityName", headerName: "Charity Name", width: 200 },
    { field: "charityNumber", headerName: "Charity Number", width: 200 },
    { field: "hours", headerName: "Hours", width: 130 },
    {
      field: "date",
      headerName: "Date",
      width: 130,
      renderCell: (params) => {
        return new Date(params.row.date).toLocaleDateString("en-GB");
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 130,

      renderCell: (params) => {
        return (
          <UploadedCaptureDelete
            handleDeleteUpload={handleDeleteUpload}
            uploadItem={params.row}
          />
        );
      },
    },
  ];

  if (getManualUploads.isLoading) {
    return <div>Loading...</div>;
  }

  if (manualHours && manualHours?.length === 0) {
    return <div></div>;
  }

  return (
    <div>
      <Stack sx={{ height: 250, width: "100%" }} spacing={2}>
        {manualHours && manualHours?.length > 0 && (
          <DataGrid
            rows={manualHours}
            columns={columns}
            getRowId={(row) => row.uuid}
            pageSize={5}
          />
        )}
      </Stack>

      <AlertPopup
        open={deleteManualUpload.isSuccess}
        message="Upload Deleted"
        severity="success"
      />
      <AlertPopup
        open={deleteManualUpload.isError}
        message="Failed to delete"
        severity="error"
      />
    </div>
  );
};

export default withAuth(UploadedCaptures);
