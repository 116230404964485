import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import PageHeader from "../../Components/Items/PageHeader";
import withAuth from "../../Hoc/withAuth";
import EventNoteTwoToneIcon from "@mui/icons-material/EventNoteTwoTone";
import { useHistory } from "react-router-dom";
import ParentAssociations from "./Users/ParentAssociations";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { useQuery } from "react-query";
import axios from "axios";
import ParentSchoolSelect from "../../Components/Forms/Parent/ParentSchoolSelect";
import ParentSetupProfile from "./Users/ParentSetupProfile";

const ParentDashBoard = ({ token }) => {
  const getMe = useQuery(
    "me",
    () => {
      return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      enabled: !!token,
      onError: () => {
        router.push("/login");
      },
    }
  );

  const user = getMe?.data?.data?.user;

  const router = useHistory();

  if (user?.userData?.profileComplete === false) {
    return <ParentSetupProfile user={user} token={token} />;
  }

  return (
    <Container>
      <PageHeader
        title="Parent Dashboard"
        info={`Welcome to the Parent Dashboard, ${user?.name}. You can see your child's progress here`}
      />

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <ParentSchoolSelect token={token} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack>
            <Stack justifyContent="center">
              <EventNoteTwoToneIcon
                sx={{
                  fontSize: 40,
                }}
              />
              <Typography variant="h6" gutterBottom>
                Manage Bookings
              </Typography>
              <Typography variant="body1" color="GrayText" gutterBottom>
                View and Manage Students
              </Typography>
            </Stack>

            {/* {relations &&
              relations.length > 0 &&
              relations.map((relation, index) => {
                return (
                  <React.Fragment key={index}>
                    <StudentBookings user={relation.User} />
                  </React.Fragment>
                );
              })} */}
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack justifyContent="center">
            <PeopleAltIcon
              sx={{
                fontSize: 40,
              }}
            />
            <Typography variant="h6" gutterBottom>
              My Associations
            </Typography>
            <Typography variant="body1" color="GrayText" gutterBottom>
              View and manage associations with your children.
            </Typography>
          </Stack>

          <ParentAssociations />

          <Button
            onClick={() => {
              router.push("/parentAssociations");
            }}>
            Manage Associations
          </Button>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack justifyContent="center">
            <QueryStatsIcon
              sx={{
                fontSize: 40,
              }}
            />
            <Typography variant="h6" gutterBottom>
              Student Stats
            </Typography>
            <Typography variant="body1" color="GrayText" gutterBottom>
              View Student Stats
            </Typography>
          </Stack>

          <Button onClick={() => router.push("/ParentViewStudentStats")}>
            View All
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withAuth(ParentDashBoard);
