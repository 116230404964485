import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  Typography,
  Paper,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { GetSchoolNews } from "../../../Redux/actions/SchoolsActions";
import withAuth from "../../../Hoc/withAuth";
import EditModal from "../../../Components/Containers/EditModal";
import DeleteSchoolDataModal from "../../../Components/Containers/DeleteSchoolDataModal";
import PageHeader from "../../../Components/Items/PageHeader";
import Masonry from "@mui/lab/Masonry";
import ViewOnlySlate from "../../../Components/Forms/ViewOnlySlate";

const MySchool = ({ user }) => {
  const { id } = useParams();
  const router = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetSchoolNews({ id }));
  }, [id, dispatch]);

  const { data } = useSelector((state) => state.schoolData) || {};

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  let permitted = user?.role === "school-admin";

  return (
    <Container>
      <PageHeader
        title="My School Notice Board"
        description="Manage your school"
      />

      {user?.role === "school-admin" && (
        <Button onClick={() => router.push(`/SchoolAdmin/recourses/${id}`)}>
          Add New
        </Button>
      )}

      <Grid
        container
        spacing={2}
        direction={isMobile ? "column-reverse" : "row"}>
        <Grid item xs={12}>
          <BasicMasonry data={data?.results} permitted={permitted} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default withAuth(MySchool);

const MoreMenu = ({ resource }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <EditModal resource={resource} />
        <DeleteSchoolDataModal id={resource.uuid} org_id={resource.org_id} />
      </Menu>
    </div>
  );
};

const ContentCard = ({ resource, permitted }) => (
  <Card
    sx={{
      border: 1,
      borderColor: resource.tag === "news" ? "error.main" : "secondary.main",
    }}>
    <CardHeader
      action={permitted && <MoreMenu resource={resource} />}
      title={resource?.title}
      subheader={new Date(resource?.updatedAt).toDateString()}
    />
    <Divider />
    <CardContent>
      <ViewOnlySlate values={resource?.data} />
    </CardContent>
  </Card>
);

const StickyNoteCard = ({ content, index, permitted }) => {
  return (
    <Grid item xs={6}>
      <Card
        sx={{
          backgroundColor: index % 2 === 0 ? "#cfc" : "#ffc",
          transform: index % 2 === 0 ? "rotate(-2deg)" : "rotate(2deg)",
          height: "15em",
          width: "15em",
          ":hover": {
            transform: "scale(1.5)",
            position: "relative",
            mt: 5,
            zIndex: 5,
          },
        }}>
        <CardHeader
          title={
            <Typography sx={{ fontFamily: "cursive" }}>
              {content.title}
            </Typography>
          }
          action={permitted && <MoreMenu resource={content} />}
        />
        <CardContent>
          <Typography variant="h5" sx={{ fontFamily: "Reenie Beanie" }}>
            {content.data}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "none",
}));

const BasicMasonry = ({ data, permitted }) => (
  <Box>
    <Masonry columns={3} spacing={2}>
      {data?.map((content, index) => (
        <Item key={index}>
          {content.tag === "sticky" ? (
            <StickyNoteCard content={content} index={index} />
          ) : (
            <ContentCard resource={content} permitted={permitted} />
          )}
        </Item>
      ))}
    </Masonry>
  </Box>
);
