import React from "react";
import { useMutation } from "react-query";
import axios from "axios";
import { Token } from "@mui/icons-material";
import UploadedCaptureDelete from "../Events/UploadedCaptureDelete";
import AlertPopup from "../../../Components/Items/AlertPopup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from "@mui/material";
import ManualUploadView from "./ManualUploadView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataGrid } from "@mui/x-data-grid";

const ManageSchoolUploads = ({ getManualUploads }) => {
  let manualHours = getManualUploads.data?.data;

  console.log("manualHours", manualHours);

  const deleteManualUpload = useMutation({
    mutationFn: (uploadItem) => {
      return axios.delete(
        `${process.env.REACT_APP_PUBLIC_URL}/manualTracking/userUploads/${uploadItem?.uuid}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
    },
  });

  const handleDeleteUpload = (uploadItem) => {
    deleteManualUpload.mutate(uploadItem, {
      onSuccess: (data) => {
        getManualUploads.refetch();
      },
    });
  };

  const columns = [
    {
      field: "uuid",
      headerName: "View",
      width: 100,
      renderCell: (params) => {
        return (
          <ManualUploadView
            manualUpload={params.row}
            captured={params.row}
            getManualUploads={getManualUploads}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      renderCell: (params) => {
        if (params.row.status === "Approved") {
          return <Chip label={"Approved"} color="primary" />;
        } else if (params.row.status === "Rejected") {
          return <Chip label={"Rejected"} color="error" />;
        } else if (params.row.status === "Pending") {
          return <Chip label={"Pending"} color="warning" variant="outlined" />;
        } else {
          return null;
        }
      },
    },
    { field: "charityName", headerName: "Charity Name", width: 200 },
    { field: "charityNumber", headerName: "Charity Number", width: 200 },
    { field: "hours", headerName: "Hours", width: 130 },
    {
      field: "date",
      headerName: "Date",
      width: 130,
      renderCell: (params) => {
        return new Date(params.row.date).toLocaleDateString("en-GB");
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 130,
      renderCell: (params) => {
        return (
          <UploadedCaptureDelete
            handleDeleteUpload={handleDeleteUpload}
            uploadItem={params.row}
          />
        );
      },
    },
  ];

  if (getManualUploads.isError) {
    return <div>Error...</div>;
  }

  if (getManualUploads.isLoading) {
    return <div>Loading...</div>;
  }

  if (manualHours && manualHours.length === 0) {
    return <div></div>;
  }

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="span">
            Manage Manual Charity Hours Uploads = {manualHours.length}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="caption" color="textSecondary">
            Use the table below to manage your schools manual charity hours
            uploads.
          </Typography>
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={manualHours}
              columns={columns}
              getRowId={(row) => row.uuid}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <AlertPopup
        open={deleteManualUpload.isSuccess}
        message="Upload Deleted"
        severity="success"
      />
      <AlertPopup
        open={deleteManualUpload.isError}
        message="Failed to delete"
        severity="error"
      />
    </div>
  );
};

export default ManageSchoolUploads;
