import * as React from "react";
import List from "@mui/material/List";

import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import withAuth from "../../../Hoc/withAuth";
import { ListItemButton } from "@mui/material";

const UserBookingList = ({ booking, token }) => {
  console.log("booking", booking);

  const router = useHistory();

  const { data, isLoading } = useQuery(
    ["image", booking?.Event?.uuid],
    async () => {
      const res = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/images/${booking?.Event?.uuid}/type/banner`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.json();
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      <ListItemButton
        alignItems="flex-start"
        onClick={() => {
          router.push(`/events/${booking?.Event.uuid}`);
        }}>
        <ListItemAvatar>
          <Avatar
            variant="square"
            alt="Remy Sharp"
            src={`${process.env.REACT_APP_PUBLIC_URL}/images/file/${data?.file_name}`}
          />
        </ListItemAvatar>
        <ListItemText
          primary={booking?.Event?.name}
          secondary={
            <Stack direction="row" justifyContent="space-between">
              <Typography
                component="span"
                variant="body2"
                sx={{ color: "text.primary", display: "inline" }}>
                Booking Date:
                {new Date(booking?.eventDate).toLocaleDateString("en-GB")}{" "}
              </Typography>

              <Typography
                component="span"
                variant="body2"
                sx={{ color: "text.primary", display: "inline" }}>
                {booking?.timeSlots &&
                  booking?.timeSlots?.length > 0 &&
                  booking?.timeSlots[0].slot}
                -{" "}
                {booking?.timeSlots &&
                  booking?.timeSlots?.length > 0 &&
                  booking?.timeSlots[booking?.timeSlots.length - 1].slot}
              </Typography>
            </Stack>
          }
        />
      </ListItemButton>

      <Divider variant="inset" component="li" />
    </List>
  );
};

export default withAuth(UserBookingList);
