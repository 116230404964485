import {
  Alert,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import withAuth from "../../../Hoc/withAuth";
import { url } from "../../../Redux/actions/URL";
import AlertPopup from "../../../Components/Items/AlertPopup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ParentAssociations = ({ token }) => {
  const { user } = useSelector((state) => state.userInfo);
  const [email, setEmail] = useState("");

  let role = user.role;

  const queryClient = useQueryClient();

  const getRelations = useQuery({
    queryKey: "getRelations",
    queryFn: () => {
      return axios.get(`${url}/relations/ParentStudentRelation`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const createNewRelation = useMutation({
    mutationKey: ["createNewRelation"],

    mutationFn: (values) => {
      return axios.put(`${url}/relations/ParentStudentRelation`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getRelations");
      getRelations.refetch();
    },
  });

  const DeleteParentRelation = useMutation({
    mutationKey: ["DeleteParentRelation"],
    mutationFn: (id) => {
      return axios.delete(`${url}/relations/ParentStudentAuthorize/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getRelations");
      getRelations.refetch();
    },
  });

  const parentConfirmStudentRelation = useMutation({
    mutationKey: ["parentConfirmStudentRelation"],
    mutationFn: (id) => {
      return axios.put(
        `${url}/relations/ParentStudentRelation/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getRelations");
      getRelations.refetch();
    },
  });

  const parentAuthStudentEvents = useMutation({
    mutationKey: ["parentDeAuthStudentEvents123"],
    mutationFn: (id) => {
      console.log("parentDeAuthStudentEvents123", id);
      return axios.put(
        `${url}/relations/ParentStudentAuthorize/${id}`,
        { sdasdsad: "dsdasda" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getRelations");
      getRelations.refetch();
    },
  });

  const parentDeAuthStudentEvents = useMutation({
    mutationKey: ["parentDeAuthStudentEvents"],
    mutationFn: (id) => {
      return axios.patch(
        `${url}/relations/ParentStudentAuthorize/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getRelations");
      getRelations.refetch();
    },
  });

  let relations = getRelations?.data?.data;

  const handleSubmit = () => {
    console.log("handleSubmit");
    let formData = {
      parentEmail:
        user.role === "parent" ? user.email.toLowerCase() : email.toLowerCase(),
      studentEmail:
        user.role === "student"
          ? user.email.toLowerCase()
          : email.toLowerCase(),
    };
    createNewRelation.mutate(formData);
    setEmail("");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Email"
            margin="normal"
            fullWidth
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          {createNewRelation.isLoading ? (
            <Button disabled color="primary" variant="contained">
              Loading...
            </Button>
          ) : (
            <Button onClick={handleSubmit} color="primary" variant="contained">
              Add New Relation
            </Button>
          )}
        </Grid>
        {getRelations.isLoading && <LinearProgress />}
        {parentDeAuthStudentEvents.isLoading && <LinearProgress />}
        {parentAuthStudentEvents.isLoading && <LinearProgress />}
        {parentConfirmStudentRelation.isLoading && <LinearProgress />}
        {DeleteParentRelation.isLoading && <LinearProgress />}
        {relations &&
          relations.length > 0 &&
          relations?.map((relation) => {
            return (
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                key={relation.uuid}>
                <Card>
                  <CardContent>
                    <Stack spacing={2}>
                      <Typography variant="h5">
                        {role === "parent"
                          ? relation?.studentEmail
                          : relation?.parentEmail}
                      </Typography>
                      <ButtonsForParent
                        parentConfirmStudentRelation={
                          parentConfirmStudentRelation
                        }
                        relation={relation}
                        getRelations={getRelations}
                        parentAuthStudentEvents={parentAuthStudentEvents}
                        parentDeAuthStudentEvents={parentDeAuthStudentEvents}
                      />
                      <DeleteRelation
                        DeleteRela={DeleteParentRelation}
                        id={relation?.uuid}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      <AlertPopup
        open={DeleteParentRelation.isSuccess}
        message="Relation Deleted Successfully"
        severity="success"
      />
      <AlertPopup
        open={DeleteParentRelation.isError}
        message="Error Deleting Relation"
        severity="error"
      />
    </>
  );
};

export default withAuth(ParentAssociations);

export const ButtonsForParent = ({
  relation,
  parentConfirmStudentRelation,
  parentAuthStudentEvents,
  parentDeAuthStudentEvents,
}) => {
  const parentConfirmStudent = (id) => {
    parentConfirmStudentRelation.mutate(id);
  };

  const parentAuthStudentEvent = (id) => {
    parentAuthStudentEvents.mutate(id);
  };
  const parentDeAuthStudentEvent = (id) => {
    parentDeAuthStudentEvents.mutate(id);
  };

  if (relation?.studentId) {
    return (
      <>
        <Stack spacing={2}>
          {!relation?.studentConfirmed ? (
            <>
              <Alert severity="warning">
                Student with the email address {`${relation.studentEmail}`} has
                not been confirmed. Please confirm the student before
                authenticating them.
              </Alert>

              <Button
                variant="contained"
                onClick={() => {
                  parentConfirmStudent(relation?.uuid);
                }}>
                Confirm Student
              </Button>
            </>
          ) : (
            <>
              {relation.studentAuthorized && relation?.studentConfirmed ? (
                <>
                  <Alert severity="success">
                    Remove the ability to join events for this student?
                  </Alert>

                  <Button
                    color="warning"
                    onClick={() => {
                      parentDeAuthStudentEvent(relation?.uuid);
                    }}>
                    Remove Ability to Join Events
                  </Button>
                </>
              ) : (
                <>
                  <Alert severity="warning">
                    Allow this student to join events?
                  </Alert>
                  <Button
                    variant="contained"
                    onClick={() => {
                      parentAuthStudentEvent(relation?.uuid);
                    }}>
                    Auth Student to join Events
                  </Button>
                </>
              )}
            </>
          )}
        </Stack>
      </>
    );
  } else {
    return (
      <>
        <Alert severity="warning">
          Student needs to Authorize you as the Parent
        </Alert>
      </>
    );
  }
};

function DeleteRelation({ id, DeleteRela }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    DeleteRela.mutate(id);

    setOpen(false);
  };

  return (
    <div>
      <Button color="error" size="small" onClick={handleClickOpen}>
        Delete Relation
      </Button>
      <Dialog
        sx={{ border: "5px solid red" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          This will Delete the relation with the parent
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the relation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            cancel
          </Button>
          <Button variant="outlined" color="error" onClick={handleDelete}>
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
