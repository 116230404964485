import { Gauge, gaugeClasses } from "@mui/x-charts";
import React from "react";

const GaugeChart = ({ goals, hours }) => {
  const settings = {
    width: 200,
    height: 200,
    value: hours,
    valueMax: goals,
  };

  return (
    <Gauge
      {...settings}
      cornerRadius="50%"
      sx={(theme) => ({
        [`& .${gaugeClasses.valueText}`]: {
          fontSize: 40,
        },
        [`& .${gaugeClasses.valueArc}`]: {
          fill: theme?.palette?.secondary?.main || "#000",
        },
        [`& .${gaugeClasses.referenceArc}`]: {
          fill: theme.palette.text.disabled,
        },
      })}
    />
  );
};

export default GaugeChart;
