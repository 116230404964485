import {
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { useSelector, useDispatch } from "react-redux";

import { getTotalStats } from "../../../Redux/actions/UserStats";
import BookingsStatsTable from "../../../Components/Containers/BookingsStatsTable";
import PageHeader from "../../../Components/Items/PageHeader";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import axios from "axios";
import withAuth from "../../../Hoc/withAuth";
import { DataGrid } from "@mui/x-data-grid";

const Stats = ({ token }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const [studentId] = useState(0);
  const [userStats, setUserStats] = useState({
    bookings: [],
  });

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  useEffect(() => {
    dispatch(getTotalStats({ studentId: id }));
  }, [studentId, dispatch, id]);

  const stats = useSelector((state) => state.stats);
  const { data } = stats;

  useEffect(() => {
    setUserStats(data);
  }, [data]);

  const getManualUploads = useQuery({
    queryKey: ["manualUploads", id],
    queryFn: async () => {
      return axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/manualTracking/userUploads/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    },
    enabled: !!token && !!id,
  });

  let capturedStats = getManualUploads?.data?.data;

  let filterNotApproved = capturedStats?.filter((upload) => {
    return upload.status === "Approved";
  });

  let filteredStats = userStats?.bookings?.filter((booking) => {
    return booking.status === "completed";
  });

  const columns = [
    { field: "charityName", headerName: "Charity Name", width: 200 },
    { field: "charityNumber", headerName: "Charity Number", width: 200 },
    { field: "hours", headerName: "Hours", width: 130 },
    {
      field: "date",
      headerName: "Date",
      width: 130,
      renderCell: (params) => {
        return new Date(params.row.date).toLocaleDateString("en-GB");
      },
    },
  ];

  return (
    <Container>
      <PageHeader title="Stats" description="View your stats" />
      <Grid container spacing={2}>
        <Grid item xl={6}>
          <Card>
            <CardContent>
              <Stack direction="row" spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    views={["day"]}
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />

                  <DatePicker
                    label="End Date"
                    views={["day"]}
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {filteredStats && <BookingsStatsTable data={filteredStats} />}

          {/* {loading ? <CircularProgress /> : <StatsTable data={userStats} />} */}
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Stack sx={{ height: "40vh" }} direction="row" spacing={2}>
            {filterNotApproved && (
              <DataGrid
                rows={filterNotApproved}
                columns={columns}
                getRowId={(row) => row.uuid}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withAuth(Stats);
