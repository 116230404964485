import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AdminGetUser } from "../../../Redux/actions/Admin";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import RelationCard from "../../../Components/Relations/RelationCard";
import UploadAvatarImage from "../../../Components/ImageUploaders/UploadAvatarImage";

const EditUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(AdminGetUser({ id }));
  }, [dispatch, id]);

  const AdminData = useSelector((state) => state.AdminData);
  const { user, loading } = AdminData;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    username: "",
  });

  useEffect(() => {
    setFormData({
      name: user?.name || "",
      email: user?.email || "",
      role: user?.role || "",
      username: user?.username || "",
    });
  }, [user]);

  return (
    <Container>
      <Typography my={4} variant="h4" align="center">
        Edit User
      </Typography>

      <UploadAvatarImage uuid={user?.uuid} size="large" />
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      <Grid container spacing={2} display="flex" justifyContent="center">
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            label="Username"
            value={formData.username}
            onChange={(e) =>
              setFormData({ ...formData, username: e.target.value })
            }
          />
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />

          <TextField
            fullWidth
            margin="normal"
            label="Email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="role_select">Role</InputLabel>
            <Select
              labelId="role_select"
              label="Role"
              value={formData.role}
              onChange={(e) =>
                setFormData({ ...formData, role: e.target.value })
              }>
              <MenuItem value="">Select Role</MenuItem>
              <MenuItem value="administrator">Administrator</MenuItem>
              <MenuItem value="student">Student</MenuItem>
              <MenuItem value="parent">Parent</MenuItem>
              <MenuItem value="org-admin">Org Admin</MenuItem>
            </Select>
          </FormControl>
          <Button sx={{ mt: 2 }} variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
      <Typography my={4} variant="h5" align="center">
        User Relations
      </Typography>
      <Grid container>
        {user?.relations.map((relation) => (
          <Grid item xs={6} xl={6} key={relation.uuid}>
            <RelationCard relation={relation} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default EditUser;
