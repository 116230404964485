import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Field, Form, Formik } from "formik";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, styled } from "@mui/system";
import {
  Alert,
  Avatar,
  Card,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

import FolderIcon from "@mui/icons-material/Folder";
import CloseIcon from "@mui/icons-material/Close";
import TextfieldWrapper from "../../../Components/Forms/FormComponents/TextfieldWrapper";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import axios from "axios";
import withAuth from "../../../Hoc/withAuth";
import DownloadFileButton from "../../../Components/Forms/DownloadFileButton";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UploadedCaptureView = ({
  token,
  user,
  ordId,
  getManualUploads,
  captured,
}) => {
  const [open, setOpen] = React.useState(false);

  const HandleSend = useMutation({
    mutationKey: ["manualUpload"],
    mutationFn: ({ id, values }) => {
      return axios.put(
        // `${process.env.REACT_APP_PUBLIC_URL}/events/manualEvent`,
        `${process.env.REACT_APP_PUBLIC_URL}/manualTracking/manualUpload`,
        values,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values) => {
    let formData = new FormData();

    values.attachments.forEach((file) => {
      formData.append("files", file);
    });

    formData.append("charityName", values.charityName);
    formData.append("charityNumber", values.charityNumber);
    formData.append("charityType", values.charityType);
    formData.append("charityCategory", values.charityCategory);
    formData.append("userId", user?.uuid);
    formData.append("orgId", ordId);
    formData.append("hours", values.hours);
    formData.append("date", values.date);
    formData.append("documents", values.documents);

    HandleSend.mutate(
      { id: values.id, values: formData },
      {
        onSuccess: (data) => {
          getManualUploads.refetch();

          // clear form on success

          values.attachments = [];
          values.charityName = "";
          values.charityNumber = "";
          values.charityType = "default";
          values.charityCategory = "None";
          values.hours = "";
          values.date = dayjs();

          handleClose();
        },
      }
    );
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        View
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Edit Capture your hours</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              id: captured?.id || "",
              charityName: captured?.charityName || "",
              charityNumber: captured?.charityNumber || "",
              charityType: "default",
              charityCategory: "None",
              hours: captured?.hours || "",
              date: captured?.date ? dayjs(captured?.date) : dayjs(),
              attachments: [],
              documents: captured?.documents || [],
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ setFieldValue, values }) => {
              return (
                <Form>
                  <Grid container spacing={2} sx={{ mt: 2, py: 2 }}>
                    <Grid item xs={12} md={6}>
                      <TextfieldWrapper
                        name="charityName"
                        label="Charity Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextfieldWrapper
                        name="charityNumber"
                        label="Charity Contact Number"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextfieldWrapper
                        name="hours"
                        label="Hours"
                        type="number"
                      />
                      {values.hours > 8 && (
                        <Alert severity="warning" sx={{ mt: 1 }}>
                          Are you sure?
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                      >
                        <DatePicker
                          format="LL"
                          variant="inline"
                          value={values.date}
                          label="Event Date"
                          onChange={(date) => setFieldValue("date", date)}
                          inputVariant="outlined"
                          fullWidth
                        />
                      </LocalizationProvider>
                    </Grid>

                    {captured.status === "Rejected" ? (
                      <Grid mt={2} item xs={12} md={6}>
                        <Chip
                          sx={{ mb: 2 }}
                          color="error"
                          label={captured.status}
                        />
                        {captured.notes && (
                          <Grid>
                            {Array.isArray(captured.notes) ? (
                              captured.notes.map((noteObj, index) => (
                                <Grid mb={2} key={index} className="note-item">
                                  <Typography>
                                    Reason: {noteObj.note}
                                  </Typography>
                                </Grid>
                              ))
                            ) : (
                              <Grid className="note-item">
                                <Typography>{captured.notes.note}</Typography>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <Card sx={{ p: 1 }} variant="outlined">
                        <CardHeader
                          sx={{ mb: 0, pb: 0.5 }}
                          title={<Typography>Attachments</Typography>}
                          subheader={
                            <Typography variant="caption" color="GrayText">
                              Please attach any supporting documents here that
                              will be approved by your teacher
                            </Typography>
                          }
                        />
                        <DownloadFileButton documents={values.documents} />
                        <Box sx={{ mb: 1 }}></Box>
                        <Field name="attachments" component={FileInput} />
                      </Card>
                    </Grid>
                  </Grid>
                  <DialogActions
                    sx={{
                      position: "sticky",
                      bottom: 0,
                      bgcolor: "background.paper",
                    }}
                  >
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" variant="contained" color="success">
                      Submit
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default withAuth(UploadedCaptureView);

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FileInput = ({ field, form }) => {
  const handleChange = (event) => {
    // set all the files

    const files = event.target.files[0];

    let Afiles = [...field.value, files];

    // setFile(files);
    form.setFieldValue("attachments", Afiles);
  };

  const removeFile = (index) => {
    let Afiles = field.value.filter((file, i) => i !== index);

    form.setFieldValue("attachments", Afiles);
  };

  return (
    <>
      {field.value &&
        field.value.length > 0 &&
        field.value.map((file, index) => {
          return (
            <ListItem
              key={index}
              sx={{ m: 0, pt: 1 }}
              alignItems="flex-start"
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => {
                    removeFile(index);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      Document Name
                    </Typography>
                  </>
                }
                secondary={
                  <>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body1"
                      color="text.primary"
                    >
                      {file.name}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          );
        })}

      <Button
        fullWidth
        component="label"
        variant="outlined"
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput type="file" onChange={handleChange} />
      </Button>
    </>
  );
};
