import * as React from "react";

import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

export default function BookingsStatsTable({ data }) {
  const rows = data?.map((row) => {
    return {
      id: row?.uuid,
      Org: row?.Event?.Org?.name,
      Event: row?.Event?.name,
      date: new Date(row?.Occurrence?.occurrence).toLocaleDateString(),
      bookedTime: `${row?.bookedTime} Hours`,
      startTime: `${row?.startTime} Hours`,
      endTime: `${row?.endTime} Hours`,
      totalAttendance: row?.totalAttendance,
    };
  });

  console.log("rows", rows);

  const columns = [
    { field: "Org", headerName: "Organisation", width: 200 },
    { field: "Event", headerName: "Event", width: 250 },
    { field: "eventDate", headerName: "Date", width: 150 },
    { field: "bookedTime", headerName: "Booked Time", width: 140 },
    { field: "startTime", headerName: "Start Time", width: 150 },
    { field: "endTime", headerName: "End Time", width: 120 },
    { field: "totalAttendance", headerName: "totalAttendance", width: 120 },
  ];

  return (
    <>
      <div style={{ height: 600, width: "100%" }}>
        <DataGridPremium
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
        />
      </div>
    </>
  );
}
