import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";

import { DataGridPremium } from "@mui/x-data-grid-premium";
import dayjs from "dayjs";

const Occurrences = ({ occurrences }) => {
  const [data, setData] = useState([]);

  console.log(occurrences);

  useEffect(() => {
    setData(
      occurrences.map((occur, index) => {
        return {
          id: index,
          occurrence: occur.occurrence,
          startTime: occur.startTime,
          endTime: occur.endTime,
          capacity: occur.capacity,
          daysOfTheWeek: dayjs(occur.occurrence).format("dddd"),
        };
      })
    );
  }, [occurrences]);

  return (
    <>
      {data && data?.length > 0 && (
        <Box sx={{ width: "100%" }}>
          <DataGridPremium
            autoHeight
            rows={data}
            getRowId={(row) => row.id}
            columns={[
              { field: "occurrence", headerName: "Occurrence", width: 200 },
              {
                field: "startTime",
                headerName: "Start Time",
                width: 100,
                valueFormatter: (params) => {
                  return dayjs(params.value).format("HH:mm");
                },
              },
              {
                field: "endTime",
                headerName: "End Time",
                width: 100,
                valueFormatter: (params) => {
                  return dayjs(params.value).format("HH:mm");
                },
              },
              { field: "capacity", headerName: "Capacity", width: 100 },
              {
                field: "daysOfTheWeek",
                headerName: "Day of the Week",
                width: 140,
              },
            ]}
          />
        </Box>
      )}
    </>
  );
};

export default Occurrences;
