import React from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import DrawerListItem from "../Components/DrawerListItem";
import withAuth from "../../../Hoc/withAuth";
import GlobalDrawerItems from "./GlobalDrawerItems";
import EventNoteTwoToneIcon from "@mui/icons-material/EventNoteTwoTone";
import Divider from "@mui/material/Divider";

const SchoolAdminDrawer = ({ user }) => {
  const data = user?.Org;

  return (
    <div>
      <DrawerListItem
        name="Dashboard"
        path="SchoolAdminDashBoard"
        Icon={DashboardIcon}
      />

      <GlobalDrawerItems />
      <Divider />
      <DrawerListItem
        name="School Events"
        path={`myEvents/${data?.uuid}`}
        Icon={EventNoteTwoToneIcon}
      />
    </div>
  );
};

export default withAuth(SchoolAdminDrawer);
