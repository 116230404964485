import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../Redux/actions/Auth";
import { useHistory } from "react-router";
import { useTheme } from "@mui/material";
import logo from "../../images/logos/NewLogoLight.png";
import logo2 from "../../images/logos/NewLogoDark.png";
import axios from "axios";
import { useQuery } from "react-query";

const Login = () => {
  const router = useHistory();
  const theme = useTheme();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  // const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const userInfo = useSelector((state) => state.userInfo);
  const { error, loading, token, user } = userInfo;

  const AuthToken = useQuery(
    ["Auth Token"],
    async () => {
      return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/token`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      enabled: !!token,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      onError: () => {
        window.localStorage.clear();
      },
    }
  );

  useEffect(() => {
    dispatch({ type: "RESET" });
  }, [dispatch]);

  useEffect(() => {
    if (token && AuthToken.isSuccess) {
      if (user.role === "student") {
        router.push("/StudentDashBoard");
      } else if (user.role === "parent") {
        router.push("/parentDashboard");
      } else if (user.role === "school-admin") {
        router.push("/SchoolAdminDashBoard");
      } else if (user.role === "school-user") {
        router.push("/schoolUserDashboard");
      } else if (user.role === "org-admin") {
        router.push("/OrgAdminDashboard");
      } else if (user.role === "org-user") {
        router.push("/orgUserDashboard");
      }
    }
  }, [token, router, user, AuthToken.isSuccess]);

  const loginHandler = (e) => {
    e.preventDefault();

    dispatch(userLogin({ email: email.toLowerCase(), password }));
  };

  return (
    <Container maxWidth="xs">
      <Box mt={10} display="flex" justifyContent="center">
        <img
          style={{
            width: "150px",
            height: "160px",
          }}
          src={theme.palette.mode === "dark" ? logo2 : logo}
          alt="logo"
        />
      </Box>
      <Typography mb={2} mt={2} variant="h5" align="center">
        Chalk It Up
      </Typography>
      <Typography variant="body1" align="center">
        Login
      </Typography>
      <TextField
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        margin="normal"
        type="email"
        variant="outlined"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoFocus
      />

      <TextField
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        margin="normal"
        required
        variant="outlined"
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
      />

      <Box mt={2}>
        {error && <Alert severity="error">{JSON.stringify(error)}</Alert>}
      </Box>
      <br />

      <Box mb={2} display="flex" justifyContent="center">
        {loading ? (
          <LoadingButton
            loading
            variant="contained"
            color="primary"
            fullWidth
            loadingIndicator="Loading...">
            Loading...
          </LoadingButton>
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={loginHandler}>
            Sign In
          </Button>
        )}
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Link to="/PasswordResetReq" variant="body2">
            Forgot password?
          </Link>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Link to="/register">{"Don't have an account? Sign Up"}</Link>
        </Grid>
      </Grid>
      <Box mt={8}>
        <Typography variant="body2" align="center">
          {"Copyright © "}
          <Link to="https://chalkitupsa.co.za">{" Chalk it Up "}</Link>
          {new Date().getFullYear()}
        </Typography>
        <Typography variant="body2" align="center">
          {"(Alpha v0.1.2)"}
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
