import React from "react";
import { Route } from "react-router-dom";
import Login from "../Screens/Auth/Login";
import PasswordReset from "../Screens/Auth/PasswordResetReq";
import ResetPassword from "../Screens/Auth/ResetPassword";
import Register from "../Screens/Auth/Register";
import VerifyEmail from "../Screens/Auth/VerifyEmail";
import Events from "../Screens/Pages/Events/Events";
import EventHome from "../Screens/Pages/Events/EventHome";
import OrgHome from "../Screens/Pages/Orgs/OrgHome";
import Orgs from "../Screens/Pages/Orgs/Orgs";
import Schools from "../Screens/Pages/Orgs/Schools";
import EventJoinCode from "../Screens/Pages/Events/EventJoinCode";
import EventEndCode from "../Screens/Pages/Events/EventEndCode";

const PublicRoutes = () => {
  return (
    <>
      <Route path="/register" exact component={Register} />
      <Route path="/login" exact component={Login} />
      <Route path="/verifyEmail" exact component={VerifyEmail} />
      <Route path="/PasswordResetReq" exact component={PasswordReset} />
      <Route path="/ResetPassword/:id" exact component={ResetPassword} />
      <Route path="/events" exact component={Events} />
      <Route path="/events/:id" component={EventHome} />
      <Route path="/org/:id" component={OrgHome} />
      <Route path="/orgs" exact component={Orgs} />
      <Route path="/schools" exact component={Schools} />
      <Route path="/EventJoinCode/:id/start" component={EventJoinCode} />
      <Route path="/EventJoinCode/:id/end" component={EventEndCode} />
    </>
  );
};

export default PublicRoutes;
